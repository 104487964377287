<!-- 运营管理 运营配置 用户黑名单 新增或编辑 -->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleFormDR">
    <ykc-form-item v-if="drawerType === 'edit'" label="用户账号" prop="orgName">
      <span>{{ row.userAccount || '—' }}</span>
    </ykc-form-item>

    <template v-if="drawerType === 'add'">
      <ykc-form-item label="限制用户" prop="operateObject">
        <ykc-radio
          :data="[
            { id: '1', name: '手动输入' },
            { id: '2', name: '批量上传' },
          ]"
          @change="onOpObjChange"
          v-model="ruleForm.operateObject"></ykc-radio>
      </ykc-form-item>

      <ykc-form-item
        v-show="this.ruleForm.operateObject === '1'"
        label="用户账号"
        prop="userAccountList">
        <el-select
          class="search-keyword-select"
          size="mini"
          v-model="ruleForm.userAccountList"
          multiple
          filterable
          clearable
          remote
          reserve-keyword
          placeholder="请输搜索关键字"
          :multiple-limit="20"
          :loading="remoteSearchLoading"
          :remote-method="customRemoteMethod">
          <el-option
            v-for="item in remoteUserList"
            :key="item.id"
            :label="item.userAccount"
            :value="item.userAccount"></el-option>
        </el-select>
      </ykc-form-item>

      <ykc-form-item
        v-show="this.ruleForm.operateObject === '2'"
        class="file-error"
        label="批量操作"
        prop="file">
        <ykc-upload
          class="user-upload-tree"
          type="xlsx"
          :size="5"
          ref="file"
          :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
          @handleRemove="handleRemove"
          @handleChange="fileUpload"
          :temText="linkText"
          :temhref="modelUrl"></ykc-upload>
      </ykc-form-item>
    </template>

    <ykc-form-item label="限制站点" prop="stationIdList">
      <ykc-tree
        ref="objectTreeOrg"
        :data="treeDataOrg"
        :props="operatorProps"
        @check-change="handleTreeDataOrg"></ykc-tree>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import YkcTree from '@/components/ykc-ui/tree/src/tree.vue';
  import { treeUtils, operationsUserDenyList } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    name: 'CreateOrEditDenyUser',
    components: {
      YkcTree,
    },
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      drawerType: {
        type: String,
        default: 'add',
      },
    },
    data() {
      return {
        remoteSearchLoading: false,
        linkText: '黑名单导入.xlsx', // 模板文本
        modelUrl: `${process.env.VUE_APP_OSS}/resource/黑名单导入.xlsx`, // // 下载模板地址
        treeDataOrg: [],
        remoteUserList: [], // 远程用户列表
        configSet: {
          label: 'userAccount',
          value: 'id',
        },
        operatorProps: {
          label: 'name',
          children: 'stationTreeVOList',
          disabled: 'disabled',
        },
        ruleForm: {
          id: '',
          accountName: '',
          operateObject: '1',
          stationIdList: [],
          userAccountList: [],
        },
        rules: {
          id: [
            { required: true, message: '请输入账号id', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('账号id'),
            },
          ],
          userAccountList: [{ required: true, message: '请选择用户账号列表', trigger: 'blur' }],
          stationIdList: [{ required: true, message: '请选择电站ID列表', trigger: 'blur' }],
        },
      };
    },
    computed: {
      formData() {
        const formData = {
          accountName: this.ruleForm.accountName,
          operateObject: '1',
          stationIdList: this.ruleForm.stationIdList,
          userAccountList: this.ruleForm.userAccountList,
        };

        if (this.row?.id) {
          formData.id = this.row?.id;
        }

        return formData;
      },
    },
    created() {
      this.getTreeData(); // 获取区域列表

      if (this.drawerType === 'edit') {
        this.ruleForm.id = this.row?.id;
        this.ruleForm.accountName = this.row?.accountName;

        if (this.row?.stationInfoVOList) {
          this.ruleForm.stationIdList = this.row.stationInfoVOList.map(item => item.stationId);
        }
      }
    },
    methods: {
      /**
       * 获取站点树数据
       */
      getTreeData() {
        treeUtils.operatorStations({}).then(res => {
          this.treeDataOrg = res?.map(item => ({
            id: item.operatorId,
            name: item.operatorName || 'Unknown',
            stationTreeVOList: item.stationTreeVOList.map(childItem => ({
              id: childItem.stationId,
              name: childItem.stationName,
            })),
          }));
          if (this.drawerType === 'edit') {
            this.$nextTick(() => {
              this.$refs.objectTreeOrg.setCheckedKeys(this.ruleForm.stationIdList, true);
            });
          }
        });
      },

      /**
       *
       */
      customRemoteMethod(query) {
        if (query !== '') {
          this.remoteSearchLoading = true;
          setTimeout(() => {
            operationsUserDenyList
              .remoteUser({ userAccount: query })
              .then(res => {
                console.log('remoteUser  res:', res);
                this.remoteUserList = res || [];
                this.remoteSearchLoading = false;
              })
              .catch(err => {
                console.error(err);
                this.remoteSearchLoading = false;
              });
          }, 200);
        } else {
          this.remoteUserList = [];
        }
      },

      /**
       *
       */
      onOpObjChange(e) {
        console.log('evt:', e);
        this.$nextTick(() => {
          this.$refs.ruleFormDR.clearValidate();
        });
      },

      /**
       * 监听操作模式改变 1 2 3
       */
      onOperateModeChange(val) {
        console.log('onOperateModeChange val:', val);
        if (['1', '2'].includes(val)) {
          this.ruleForm.operateIdList = [];
        }
      },

      /**
       *
       */
      onConfirm(done) {
        console.log('done:', done);
      },

      /**
       *
       */
      handleTreeDataOrg() {
        this.ruleForm.stationIdList = this.$refs.objectTreeOrg.getCheckedKeys(true) || [];
      },

      /**
       *
       */
      handleRemove() {
        this.ruleForm.file = undefined;
      },

      /**
       *
       */
      fileUpload(file, fileList, isCheck) {
        if (isCheck) {
          this.ruleForm.file = file.raw;
          const formData = new FormData();
          const param = {
            file: this.ruleForm.file,
          };
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          operationsUserDenyList
            .importUser4BlackList(formData)
            .then(res => {
              console.log('res---导入用户列表信息:\n', res);
              this.$message({
                message: '导入文件成功',
                type: 'success',
              });

              this.remoteUserList = res?.importUserDTOS.map((item, i) => {
                return {
                  id: i,
                  userAccount: item.userAccount,
                };
              });

              const userAccountList = this.remoteUserList
                ?.map(item => item.userAccount)
                .slice(0, this.ruleForm.operateObject === '1' ? 20 : 2000);
              this.ruleForm.userAccountList = userAccountList || [];
            })
            .catch(error => {
              this.$refs.file.fileList = [];
              if (error) {
                console.log(error?.resultDesc);
              }
            });
        }
        // validate the ruleForm's file object
        this.$refs?.ruleFormDR?.validateField('file');
      },

      /**
       * 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       */
      submitForm() {
        console.log('this.ruleForm++++', this.ruleForm);
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (this.drawerType === 'add') {
              const params = (({ stationIdList, userAccountList }) => ({
                stationIdList,
                userAccountList,
              }))(this.formData);
              operationsUserDenyList
                .createNewDenyItem({ ...params })
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '新增成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              console.log('编辑', this.formData);
              operationsUserDenyList
                .updateDenyItem(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '编辑成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /**
       *校验规则
       */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleFormDR.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .user-upload-tree {
    margin-bottom: 22px;
  }
  .el-select.search-keyword-select.el-select--mini {
    width: 100%;
  }
</style>
