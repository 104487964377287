<!-- 运营管理 运营配置 用户黑名单 列表 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        title="用户黑名单列表"
        :selectable="false"
        :selectOnIndeterminate="false"
        :data="tableData"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())"
        @selection-change="selectionChange">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'operation:config:black:add'" type="plain" @click="doNewDenyItem">
              新增
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>

    <ykc-drawer
      :show.sync="showDrawer"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      :title="currentRow.id ? '编辑用户黑名单' : '新增用户黑名单'">
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit
          ref="addOrEdit"
          :row="currentRow"
          :drawerType="drawerType"
          v-if="drawerType === 'add' || drawerType === 'edit'"></AddOrEdit>
      </div>
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import { mapMutations } from 'vuex';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { operationsUserDenyList } from '@/service/apis';
  import { localGetItem, sessionSetItem, code } from '@/utils';

  import AddOrEdit from './AddOrEdit.vue';

  export default {
    name: 'OperationDenyUserList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        showDrawer: false,
        drawerType: 'add',
        dictionary: {},
        currentRow: {}, // 当前用户
        selectItems: [],
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        tableData: [{ id: '111' }],

        tableOperateButtons: [
          {
            enabled: () => code('operation:config:black:edit'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.currentRow = { ...row };
                    this.drawerType = 'edit';
                    this.showDrawer = true;
                  }}>
                  编辑
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('operation:config:black:detail'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.gotoDetailPage(row);
                  }}>
                  详情
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('operation:config:black:stop'),
            render: (h, button, { row }) => {
              const oppositeTips = row.status === 1 ? '停用' : '启用';
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doStartOrStop(row);
                  }}>
                  {oppositeTips}
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          {
            minWidth: '200px',
            label: '用户账号',
            prop: 'userAccount',
            scopedSlots: { defaultTitle: () => '' },
          },
          {
            label: '限制站点',
            minWidth: '300px',
            prop: 'stationInfoVOList',
            title: false,
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const { stationInfoVOList } = row;
                const reducer = (accumulator, currentValue) => `${accumulator} ${currentValue}`;
                const labels = stationInfoVOList?.map(item => item.stationName).reduce(reducer);
                return (
                  <el-tooltip effect="dark" content={labels} placement="top-start">
                    <span>{labels}</span>
                  </el-tooltip>
                );
              },
            },
          },
          {
            label: '状态',
            minWidth: '100px',
            prop: 'status',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const title =
                  this.dictionary?.find(o => Number(o.id) === Number(row.status))?.name ?? '——';
                return <span>{title}</span>;
              },
            },
          },
        ],
        pageInfo: {
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          userAccount: '',
          stationName: '',
          status: '',
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '状态',
            placeholder: '请选择状态',
            data: [...this.dictionary],
          },
        ];
      },
    },
    created() {
      const dictionary = localGetItem('dictionary');
      if (dictionary?.user_black_list_state) {
        this.dictionary = dictionary.user_black_list_state.map(item => ({
          id: item.code,
          name: item.name,
        }));
      }
      this.searchTableList();
    },
    methods: {
      ...mapMutations(['setDenyListCurrentStationIds']),

      /**
       * @desc 获取列表数据
       * */
      searchTableList() {
        const reqParamsOfDeny = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        operationsUserDenyList.list(reqParamsOfDeny).then(response => {
          this.tableData = response?.records || [];
          this.pageInfo.total = response?.total || 0;
        });
      },

      /**
       * @desc 新增
       * */
      doNewDenyItem() {
        this.currentRow = Object.create(null);
        this.drawerType = 'add';
        this.showDrawer = true;
      },

      /**
       * @desc 跳转详情
       * */
      gotoDetailPage(row) {
        const ids = row.stationInfoVOList.map(item => item.stationId);
        sessionSetItem('denyListCurrentStationIds', JSON.stringify(ids));
        this.$router.push({
          path: '/operationCenter/operationConfig/userBlackList/details',
          query: {
            id: row.id,
            status: row.status,
            userAccount: row.userAccount,
            limit: row.stationInfoVOList.length,
          },
        });
      },

      /**
       * @desc 停用/启用
       * */
      doStartOrStop(row) {
        console.log(row.status, typeof row.status);
        const prefix = '确定要';
        const postfix = '？';
        const oppositeTips = row.status === 1 ? '停用' : '启用';
        const oppositeStatus = row.status === 1 ? 0 : 1;
        YkcDialog({
          dialogType: 'feedback',
          showFooter: true,
          title: `${prefix}${oppositeTips}${postfix}`,
          onClose: done => {
            done();
          },
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            const stopOrStartReqParams = {
              id: row.id,
              status: oppositeStatus,
            };
            operationsUserDenyList
              .startOrStop(stopOrStartReqParams)
              .then(res => {
                console.log(res);
                this.$message.success(`${oppositeTips}成功`);
                this.searchTableList();
              })
              .catch(err => {
                console.error(err);
                this.$message.success(err.resultDesc);
              });

            done();
          },
        });
      },

      /** 获取勾选id */
      selectionChange(selection) {
        this.selectItems = selection.map(item => {
          return item.activityId;
        });
      },

      /** 点击查询按钮 */
      handleSearchConfirm() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },

      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },

      /** 抽屉右上角关闭按钮 callback */
      onAddDrawerClose(done) {
        done();
      },

      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            // 点击确认取消保存后，要重置表单
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },

      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },

      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .export-btn {
    margin-left: 5px;
  }
</style>
